<template>
  <div class="page-container stock-quotation-page">
    <portal to="page-name">Cadastro de cotação</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Cadastro de cotação</div>
      </div>
      <div class="card-body">
        <div v-if="finished">
          <small class="text-info">* Esta cotação já foi finalizada</small>
          <div class="card-detail">
            <div class="card-detail-title">Dados da cotação</div>
            <div class="card-detail-data">
              <div class="columns">
                <div class="column col-12 form-group">
                  <label class="form-label text-bold">Data</label>
                  {{ form.date | date('DD/MM/YYYY HH:mm') }}
                </div>
                <div class="column col-12 form-group">
                  <label class="form-label text-bold">Responsável</label>
                  {{ user.name }}
                </div>
                <div class="column col-12 form-group">
                  <label class="form-label text-bold">Título</label>
                  {{ form.name }}
                </div>
                <div class="column col-12 form-group" v-if="form.validity">
                  <label class="form-label text-bold">Validade</label>
                  {{ form.validity | date }}
                </div>
                <div class="column col-12 form-group" v-if="form.notes">
                  <label class="form-label text-bold">Observações</label>
                  {{ form.notes }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-detail">
            <div class="card-detail-title">Condições de pagamento</div>
            <div class="card-detail-data">
              <table class="table">
                <thead>
                <tr>
                  <th class="hide-sm" width="40px">#</th>
                  <th>Nome</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.paymentConditions" :key="i">
                  <td class="hide-sm">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-detail">
            <div class="card-detail-title">Produtos</div>
            <div class="card-detail-data">
              <table class="table">
                <thead>
                <tr>
                  <th class="hide-sm" width="40px">#</th>
                  <th>Produto</th>
                  <th class="text-center">Unidade</th>
                  <th class="text-center">Quantidade</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.products" :key="i">
                  <td class="hide-sm">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ measure.getName(item.unit) }}</td>
                  <td class="text-center" width="100px">
                    {{ item.quantity | number(3) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-detail">
            <div class="card-detail-title">Fornecedor(es)</div>
            <div class="card-detail-data">
              <table class="table">
                <thead>
                <tr>
                  <th class="hide-sm" width="40px">#</th>
                  <th>Nome</th>
                  <th>Email</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.providers" :key="i">
                  <td class="hide-sm">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="columns">
            <div class="column col-3 col-md-6 col-sm-12 form-group">
              <label class="form-label">Data</label>
              <dx-input-date
                id="date" name="date" class="form-input"
                v-model="form.date" readonly/>
            </div>
            <div class="column col-4 col-md-6 col-sm-12 form-group">
              <label class="form-label">Responsável</label>
              <input type="text" id="user" class="form-input"
                     v-model="user.name" readonly/>
            </div>
          </div>
          <div class="columns">
            <div class="column col-5 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.name.$error}">
              <label for="name" class="form-label">Título</label>
              <input type="text" id="name" class="form-input"
                     v-model="form.name"/>
              <template v-if="$v.form.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.name.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-2 form-group"
                 :class="{'has-error': $v.form.validity.$error}">
              <label class="form-label">Validade</label>
              <dx-input-date
                id="appointment-insurance-validity"
                class="form-input" placeholder="00/00/0000"
                v-model="form.validity"
                @blur="$v.form.validity.$touch()"
              />
              <template v-if="$v.form.validity.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.validity.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.validity.minDate">
                  data vencida
                </div>
              </template>
            </div>
            <div class="column col-5 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.purpose.$error}">
              <label for="purpose" class="form-label">Finalidade</label>
              <select id="purpose" name="purpose" class="form-select"
                      v-model="form.purpose"
                      @blur="$v.form.purpose.$touch()">
                <option value="">[Selecione]</option>
                <option value="quotation">Cotação de compra</option>
              </select>
              <template v-if="$v.form.purpose.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.purpose.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-12 form-group">
              <label for="account-notes" class="form-label">Observações</label>
              <textarea id="account-notes" class="form-input" rows="2"
                        v-model="form.notes">
            </textarea>
            </div>
          </div>
          <div class="mt-2">
            <label class="form-label">Condições de pagamento</label>
            <table class="table table-conditions">
              <tbody>
              <tr v-for="(item, i) in paymentConditions" :key="i"
                  :class="item.selected ? 'selected-item' : ''"
                  @click="item.selected = !item.selected">
                <td width="35px" :class="item.selected ? 'text-secondary' : 'text-gray-light'">
                  <fa-icon :icon="['fal', 'check']" />
                </td>
                <td :class="item.selected ? 'text-bold' : 'text-normal'">
                  {{ item.name }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-detail mt-2">
            <label class="form-label">Produto</label>
            <dx-autocomplete
              id="product"
              ref="productAutocomplete"
              v-model="product"
              :source="findProduct"
              :custom-label="setProductLabel"
              track-by="id"
              @select="selectProductItem"
              :debounce="800"
              placeholder="Informe o nome, código ou lote"
            >
              <button slot="action"
                      class="btn btn-action input-group-btn btn-icon btn-neutral"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
              <template v-slot="{ item }">
                <div>{{ item.name }} {{ item.complement }}</div>
              </template>
            </dx-autocomplete>

            <div class="empty" v-if="form.products.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Produtos</p>
              <p class="empty-subtitle">
                Nenhum produto adicionado. Utilize o campo acima para realizar a busca.
              </p>
            </div>
            <table class="table table-striped table-hover form-group" v-else>
              <thead>
              <tr>
                <th class="hide-sm" width="40px">#</th>
                <th>Produto</th>
                <th class="text-center">Unidade</th>
                <th class="text-center">Quantidade</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in form.products" :key="i">
                <td class="hide-sm">{{ i + 1 }}</td>
                <td>{{ item.name }} {{ item.complement }}</td>
                <td class="text-center">{{ measure.getName(item.outputUnit) }}</td>
                <td class="text-center" width="100px">
                  <dx-input-number v-model="item.quantity"
                                   class="form-input text-center"
                                   :precision="3" maxlength="7"/>
                </td>
                <td class="text-right" width="100px">
                  <button class="btn btn-sm btn-action btn-icon btn-error"
                          @click="removeProductItem(item.id, i)">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-detail">
            <label class="form-label">Fornecedor(es)</label>
            <dx-autocomplete
              id="provider"
              ref="providerAutocomplete"
              v-model="provider"
              :source="findProvider"
              :custom-label="setProviderLabel"
              track-by="id"
              @select="selectProviderItem"
              :debounce="800"
              placeholder="Informe o nome, código ou lote"
            >
              <button slot="action"
                      class="btn btn-action input-group-btn btn-icon btn-neutral"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
              <template v-slot="{ item }">
                <div>{{ item.name }}</div>
              </template>
            </dx-autocomplete>
            <div class="empty" v-if="form.providers.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Fornecedores</p>
              <p class="empty-subtitle">
                Nenhum fornecedor adicionado. Utilize o campo acima para realizar a busca.
              </p>
            </div>
            <table class="table table-striped table-hover form-group" v-else>
              <thead>
              <tr>
                <th class="hide-sm" width="40px">#</th>
                <th>Nome</th>
                <th>Email</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in form.providers" :key="i">
                <td class="hide-sm">{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td class="text-right" width="100px">
                  <button class="btn btn-sm btn-action btn-icon btn-error"
                          @click="removeProviderItem(item.id, i)">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="!finished"
                @click="save(false)"
                :disabled="finishing || saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn btn-warning mr-1"
                v-if="!finished"
                @click="save(true)"
                :disabled="finishing || saving"
                :class="{loading: finishing}">
          Finalizar
        </button>
        <button class="btn btn-gray btn-icon-left mr-1"
                :class="{loading: printing}"
                :disabled="!finished"
                @click="print">
          <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import moment from 'moment';
import {
  date,
  minDate,
} from 'src/data/validators';
import { mapState } from 'vuex';
import * as measure from 'src/data/measure-units';
import { FINISH_QUOTATION, PRINT_QUOTATION } from '@/data/actions/modules/stock';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      measure,
      isNew: false,
      loading: false,
      saving: false,
      finishing: false,
      printing: false,
      product: null,
      provider: null,
      paymentConditions: [
        { code: '0', name: 'À vista', selected: false },
        { code: '30', name: '30 dias', selected: false },
        { code: '60', name: '60 dias', selected: false },
        { code: '90', name: '90 dias', selected: false },
        { code: '120', name: '120 dias', selected: false },
        { code: '150', name: '150 dias', selected: false },
        { code: '180', name: '180 dias', selected: false },
      ],
      form: this.blankForm(),
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    finished() {
      return this.form.status === 'finished';
    },
    canAccessFinishQuotation() {
      if (FINISH_QUOTATION) {
        return this.$can(FINISH_QUOTATION);
      }
      return true;
    },
    canAccessPrintQuotation() {
      if (PRINT_QUOTATION) {
        return this.$can(PRINT_QUOTATION);
      }
      return true;
    },
  },
  validations() {
    return {
      form: {
        date: { required },
        name: { required },
        validity: { date, minDate: minDate() },
        purpose: { required },
      },
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/stock-quotations/${this.form.id}`)
        .then(({ data }) => {
          this.form = {
            id: data.id,
            name: data.name,
            date: data.date,
            validity: data.validity,
            purpose: data.purpose,
            status: data.status,
            notes: data.notes,
            paymentConditions: data.paymentConditions,
            products: data.products,
            providers: data.providers || [],
            user: {
              id: this.user.id,
              name: this.user.name,
            },
          };
          data.paymentConditions.forEach(({ code }) => {
            const foundCondition = this.paymentConditions
              .find(payment => payment.code === code);
            foundCondition.selected = true;
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async save(finished = false) {
      if (finished && !this.canAccessFinishQuotation) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (!this.paymentConditions.some(item => item.selected)) {
        this.$toast.show('Informe as condições de pagamento', { type: 'error' });
        return;
      }

      if (this.form.products.length === 0) {
        this.$toast.show('Adicione o(s) produto(s)', { type: 'error' });
        return;
      }

      if (this.form.providers.length === 0) {
        this.$toast.show('Adicione o(s) fornecedor(es)', { type: 'error' });
        return;
      }

      this.saving = true;
      this.finishing = finished;

      this.form.status = finished ? 'finished' : 'open';
      const quotationData = this.clone(this.form);
      quotationData.paymentConditions = this.paymentConditions
        .filter(item => item.selected)
        .map(item => ({
          code: item.code,
          name: item.name,
        }));

      const request = this.isNew
        ? this.$http.post('/stock-quotations', quotationData)
        : this.$http.put(`/stock-quotations/${this.form.id}`, quotationData);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/quotations/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.finishing = finished;
          this.saving = false;
        });
    },
    print() {
      if (!this.canAccessPrintQuotation) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$toast.show('Em desenvolvimento!', { type: 'error' });
    },
    findProduct(text) {
      const value = text.trim();

      const params = {
        search: value,
        limit: 20,
      };

      return this.$http.get('/products', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    setProductLabel(item) {
      return item.name || null;
    },
    selectProductItem(item) {
      const found = this.form.products.find(({ id }) => id === item.id);

      if (!found) {
        this.form.products.push({
          id: item.id,
          name: item.name,
          complement: item.complement,
          outputUnit: item.outputUnit,
          quantity: 1,
        });
      } else {
        found.quantity += 1;
      }

      this.product = null;
    },
    removeProductItem(id, i) {
      this.form.products.splice(i, 1);
    },
    findProvider(text) {
      const value = text.trim();

      const params = {
        search: value,
        limit: 10,
      };

      return this.$http.get('/providers', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    setProviderLabel(item) {
      return item.name || null;
    },
    selectProviderItem(item) {
      const found = this.form.providers.find(({ id }) => id === item.id);

      if (!found) {
        this.form.providers.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }

      this.provider = null;
    },
    removeProviderItem(id, i) {
      this.form.providers.splice(i, 1);
    },
    blankForm() {
      return {
        id: '',
        date: moment().format('YYYY-MM-DD[T]HH:mm'),
        name: '',
        validity: moment().add(1, 'month')
          .format('YYYY-MM-DD'),
        purpose: '',
        status: '',
        notes: '',
        user: {
          id: '',
          name: '',
        },
        paymentConditions: [],
        products: [],
        providers: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-quotation-page {
  .card-detail {
    background-color: lighten($gray-color-light, 5%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      margin-bottom: $layout-spacing;
    }
  }
  .empty {
    margin-top: $layout-spacing-lg;
    padding: $layout-spacing-sm;
  }
  .form-checkbox {
    line-height: 1rem;
    margin: 0;
    min-height: 0;
    i {
      top: 0.15rem;
    }
  }
  .table-conditions {
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    .selected-item {
      background-color: $gray-color-ultra-light;
    }
    td {
      padding: $layout-spacing;
      cursor: pointer;
      .form-checkbox {
        padding: 0;
      }
    }
    svg {
      font-size: .9rem;
    }
  }
}
</style>
